/* Global Box Styles */
.MuiBox-root {
  box-sizing: border-box;
}

/* Change Attributions */
.mapbox-attribution-container .attrition-logo .mapboxgl-ctrl-logo {
  display: none;
}

.mapbox-attribution-container > div:last-child a[href='https://kepler.gl/policy/'] {
  display: none;
}

.mapbox-attribution-container > div:last-child a[href='https://www.mapbox.com/map-feedback/'] {
  display: none;
}

/* Remove Map Draw And Locale Action Panels */
.map-control > div:nth-child(3), .map-control > div:nth-child(4) {
  display: none;
}

/* Date Time Picker Icons */
::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: 'pointer';
}

/* Marzipano 360 Slider Helper */
.interface {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -ms-content-zooming: none;
  opacity: 0.99;
}